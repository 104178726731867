/**
 * CONTENTS
 *
 *
 * SECTION 1
 * Name.................Info here
 * Name.................Info here
 *                      line 2
 *
 * SECTION 2
 * Name.................Info here
 * Name.................Info here
 * 
 *
 **/

   .responsive {
  width: 100%;
  height: auto;
}



/*------------------------------------*\
# header
\*------------------------------------*/

// c-header


.c-header {
	z-index: 9999;
	position: relative;

	// margin-bottom: 40px;
	
	overflow: hidden;
	height: 280px;

	// mob
	@include breakpoint(medium down) {
		// height: calc(100vh - 368px) !important;
		// height: 170%;
	}

	// dt
	@include breakpoint(large) {
		// height: calc(100vh - 368px) !important;
		//min-height: 400px;

	}

	img {
		// position: absolute; bottom: 0;
		object-fit: cover;
		// object-fit: fill;

		@include breakpoint(medium down) {
			// object-fit: fill;

		}


	}

	&__text {

		// color: $color-ui-bar-background-grey;
		color: $color-brand-primary;
		
		// mob
		@include breakpoint(medium down) {
			// @include vertical-center;
			margin-top: rem-calc(20);
			margin-top: rem-calc(80);
			font-size: rem-calc(18);
			line-height: rem-calc(26);
		}
		// tablet
		@include breakpoint(medium) {
			margin-top: rem-calc(40);
			margin-top: rem-calc(80);
			font-size: rem-calc(22);
			line-height: rem-calc(32);
		}
		// dt
		@include breakpoint(large) {
			margin-top: rem-calc(80);
			font-size: rem-calc(27);
			line-height: rem-calc(39);
		}


	}



		

//can use '> .class', or '> div'
> .colour-wash {
	// display: none;

	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	// background: rgba(180, 0, 102, 0.75); // pink
	// background: rgba(248, 231, 28, 0.8);
	background: rgba(255, 235, 0, 0.7);
	z-index: 1;
	// mix-blend-mode: multiply;
	//opacity: 1;
}
}

.c-header__content {
	position:absolute;
	display: block;
	top:60px;
			@include breakpoint(small only) {
			top:90px;

		}
	left:0;
	height: 100%;
	width: 100%;
	z-index: 2;

	h1 {
		text-align: right;

		@include breakpoint(small only) {
			text-align: left;

		}
	}
}

.content-holder {
	z-index: 9;
	position: relative;
	overflow: hidden;
	margin: 0;

//can use '> .class', or '> div'

}

.contained {
	margin-top: 30px;
}

.image-caption {
	font-size: 13px;
}

