/**
 * CONTENTS
 *
 *
 * BRAND COLORS
 * Primary..............and others
 * Secondary............and others
 * Utility..............and others
 * Text.................and others
 *
 *
 * UNITS
 * Spacing..............responsive via multiplication
 * 
 *
 **/



/*------------------------------------*\
  # BRAND COLORS
\*------------------------------------*/

// primary colors
$color-brand-primary: #FFE800;
$color-brand-primary--highlight:     lighten($color-brand-primary, 5%) !default;
$color-brand-primary--shadow:        darken($color-brand-primary,  5%) !default;

// secondary colors
$color-brand-secondary: #115BA1;
$color-brand-secondary-highlight:     lighten($color-brand-secondary, 5%) !default;
$color-brand-secondary--shadow:       darken($color-brand-secondary,  5%) !default;


$highlighted: #FAE63A;
// utility colors
$color-brand-white: #ffffff;
$color-brand-black: #000000;

// text colors
$color-text-body-copy: #828282;
$color-text-headline: $color-brand-secondary;
$color-text-subhead: $color-brand-secondary;

// ui colors
// $color-ui-card-background-grey: #f7f7f7;
// $color-ui-outline-grey: #dcdcdc;
// $color-ui-section-grey: #fbfbfd;
// $color-color-ui-slider-bullet-grey: #d8d8d8;
// $color-ui-shadow-color: #acacac;
// $color-ui-solid-button-hover: #EB5A38;
// $color-ui-placeholder-grey: #b2b2b2;



/*------------------------------------*\
  # EFFECTS
\*------------------------------------*/

// ui shadow settings
// $ui-shadow-opacity: 0.5;
// $ui-shadow-blur: 24px;
// $ui-shadow-offset-x: 6px;
// $ui-shadow-offset-y: $ui-shadow-offset-x;



/*------------------------------------*\
  # TYPOGRAPHY
\*------------------------------------*/

// fonts
// $brand-font-body-copy: "proxima-nova", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
// $brand-font-headline: "brandon-grotesque", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
// $brand-font-button: $brand-font-headline;

// font weights
// $brand-font-weight-thin: 100;
// $brand-font-weight-extra-light: 200;
// $brand-font-weight-light: 300;
// $brand-font-weight-normal: 400;
// $brand-font-weight-medium: 500;
// $brand-font-weight-semi-bold: 600;
// $brand-font-weight-bold: 700;
// $brand-font-weight-black: 800;



/*------------------------------------*\
  # UNITS
\*------------------------------------*/

// spacing
$brand-spacing-level-1: 8px; // mobile
$brand-spacing-level-2: $brand-spacing-level-1 * 2;  // tablet
$brand-spacing-level-3: $brand-spacing-level-1 * 3;  // desktop


h1,h2,h3,h4,h5,h6 {
	// text-transform: uppercase;
	// font-weight: 700!important;
	// letter-spacing: 1px;
}


