/**
 * CONTENTS
 *
 *
 * SECTION 1
 * Name.................Info here
 * Name.................Info here
 *                      line 2
 *
 * SECTION 2
 * Name.................Info here
 * Name.................Info here
 * 
 *
 **/

 .nav-bar {
	// Display & Box model
	z-index: 999999;
	position: relative;
	width: 100%;
	height: 120px;	
	padding: 0;
	// border-bottom: 6px solid darken($color-brand-primary, 13%); 
		border-bottom: 0px solid black; 
	// mob
	@include breakpoint(medium down) {
		height: 88px;
	}

	/*Color*/	
	background: $color-brand-primary;

	/*nested*/
	&__header {
		width: 100%;
		/*height: 440px;	
		background-color: red;*/
		// display: flex;
	padding: 0;
	}

	//margin-bottom: 260px;
}

.logo {
	position: absolute;
	width: 280px;
	// mob
	@include breakpoint(medium down) {
		width: 180px;
	}
	/*height: 140px;*/
	// padding-top: 40px;
	// align-self: flex-start;
}

.main-menu {
	
	// display: flex;
	// align-items: center;
	position: absolute;
	right: 10px;
	padding: 0;
	
	//height: 140px;
	@include breakpoint(medium down) {
		 //height: 88px;
		 overflow: hidden;
	}

	// width: 100px;
	// overflow: hidden;
	
	
	
	

	// justify-self: flex-end;

	li {

		

		font-size: 20px;

		margin: 0; padding-top: 0; padding-bottom: 0;
		// height: 140px;
		
		// padding-top: 55px;
	 position: relative;
 //    top: 50%;
 //    transform: translateY(-50%)!important;
 //    padding-top: 30px;
	// padding-top: 65px;
	//background-color: yellow;
	
 

	@include breakpoint(medium down) {
		// height: 88px;
	}

	a {
		margin: 0;
		display: block;
		color: $black;
		//height: 140px;
		 display: table-cell;
           //height: 140px;
            list-style-type: none;
           // margin: 10px;
            vertical-align: middle;

	}
	}

	.current {
		background-color: darken($color-brand-primary, 11%); 

		

	}
}

.product-menu {
	display: flex;
	justify-content: space-between;
}

.product-menu__item {
	img {
		width: 200px;
	}
}

.drilldown  a {
	color: black;
}

.js-drilldown-back a {
	color: #115BA1;
}

.js-drilldown-back {

}

.title-bar {

}

.breadcrumbs {


	margin-top: -11px;
	float: right;
	padding-bottom: 20px;
	margin-right: 25px;
}

