/**
 * CONTENTS
 *
 *
 * SECTION 1
 * Name.................Info here
 * Name.................Info here
 *                      line 2
 *
 * SECTION 2
 * Name.................Info here
 * Name.................Info here
 * 
 *
 **/

 .small {
 	font-size: 13px;
 }

 p {
 	font-size: 18px;
 }

 .breadcrumbs li {
 	text-transform: none;
 	font-size: 14px;
 }

 .usp {
 	text-align: left;
 	margin-top: 200px;
 }

 .address {
 	margin-top: 18px;
 	font-size: 14px;
 	line-height: 24px;
 }
 .intro-h2 {
	 margin-top: 35px;
 }