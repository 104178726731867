/**
 * CONTENTS
 *
 *
 * SECTION 1
 * Name.................Info here
 * Name.................Info here
 *                      line 2
 *
 * SECTION 2
 * Name.................Info here
 * Name.................Info here
 * 
 *
 **/

 .product-nav {
 	background-color: $color-brand-primary;
 	height: 200px;
 	margin-bottom: 200px!important;
 	position: relative;
 }
.feature__text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	// background-color: #F7F7F7;

	padding: 40px;
}

.gray {
	
	// background-color: #F7F7F7;
	border-bottom: 6px solid #FAE63A;

}

 .breadcrumbtrail {
	// padding: 15px;

// padding-left: 20px;
// margin-bottom: 0px;
// margin-top: 20px;
	// border: solid 4px $yellow;
	// border-bottom: none;
	background-color: $color-brand-primary;
	border-radius: 10px 10px 0px 0px;
-moz-border-radius: 10px 10px 0px 0px;
-webkit-border-radius: 10px 10px 0px 0px;
	 position: relative;
	//top: 34px;
	// left: 350px;
	width: 74%;
	min-height: 73px;
	margin-top: 80px;
}


.category_product {
	padding-top: 40px;
	padding-bottom: 10px;
	//margin-top: 60px;
	border-top: 4px solid $color-brand-primary;
	// border-bottom: 4px solid $color-brand-primary;
	//margin-bottom: 120px;
}

.quote {
	border-top: 4px solid $color-brand-primary;
	// border-bottom: 4px solid $color-brand-primary;
}

.quote__text {
	font-size: 24px;
}

.spec {

	li {
		// border-bottom: 2px solid $color-brand-primary;
		width: 100%;
		font-weight: bold;
		padding: 7px 0;
	}
}

tr {
	font-size: 15px;
}

.v-divider  {
	border-right: 2px solid $color-brand-primary;
}

.datasheet {
	font-size: 20px;
	line-height: 10px;
}

.table-title {
	width: 100%;
	font-size: 20px;
	font-weight: bold;
}

.table-description {
	width: 100%;
	font-size: 14px;
}

.table-container {
	border-top: 2px dotted $color-brand-primary;
	// border-bottom: 4px dotted $color-brand-primary;
	padding-top: 20px;
	padding-bottom: 10px;
	margin-top: 20px;
}

table {
	margin-bottom: 30px;
}

.button.secondary {
	color: $primary-color;
	font-weight: bold;
	border-radius: 10px 10px 10px 10px;
-moz-border-radius: 10px 10px 10px 10px;
-webkit-border-radius: 10px 10px 10px 10px;
}

// themes

.light {
	background: $light-gray;
	padding: 30px;
}

.overview-cb {
	height: 60px;
	margin-top: 20px;
	margin-bottom: -40px;
	border-top: 6px solid $color-brand-primary;
	border-right: 6px solid $color-brand-primary;
	border-left: 6px solid $color-brand-primary;
	-webkit-border-top-left-radius: 12px;
-moz-border-border-top-left-radius: 12px;
border-border-top-left-radius: 12px;
-webkit-border-top-right-radius: 12px;
-moz-border-border-top-right-radius: 12px;
border-border-top-right-radius: 12px;
}
.overview {
	height: 60px;
	margin-top: 20px;
	border-top: 6px solid $color-brand-primary;
	border-right: 6px solid $color-brand-primary;
	border-left: 6px solid $color-brand-primary;
	-webkit-border-top-left-radius: 12px;
-moz-border-border-top-left-radius: 12px;
border-border-top-left-radius: 12px;
-webkit-border-top-right-radius: 12px;
-moz-border-border-top-right-radius: 12px;
border-border-top-right-radius: 12px;
}
.overview-rev {
	height: 60px;
	margin-bottom: 50px;
	border-bottom: 6px solid $color-brand-primary;
	border-right: 6px solid $color-brand-primary;
	border-left: 6px solid $color-brand-primary;
	-webkit-border-bottom-left-radius: 12px;
-moz-border-border-bottom-left-radius: 12px;
border-border-bottom-left-radius: 12px;
-webkit-border-bottom-right-radius: 12px;
-moz-border-border-bottom-right-radius: 12px;
border-border-bottom-right-radius: 12px;
}
.column-content {
	padding: 20px;
}
.highlighted {

	position: relative;
z-index: 3;

	background: $highlighted;
	padding: 20px;

	li {
		font-size: 24px;

			@include breakpoint(medium down) {
			font-size: 20px;

		}

	}
}

.multi-column {
	background: $white;
	border-radius: 0 0 12px 12px;
position: relative;
z-index: 3;
// box-shadow: 0 10px 10px -5px #cccccc;

}


.light > .multi-column {
	background: none!important;
	position: relative;
z-index: 3;
}

.full-width {
	
	z-index: 1;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -10em;
}

.team {
	margin-bottom: 40px;
}

.content-block {
	h3 {
		margin-bottom: 40px;
			@include breakpoint(medium down) {
			margin-bottom: 20px;

		}
	}
}

.category-nav {
	//background: #F1F1F1;
	height: 50px;
	padding: 1px;
	padding-top: 25px;
	// margin-bottom: 80px;
	position: absolute;
	z-index: 99999;
	width: 100%;
}

.entry-nav {
	//background: #F1F1F1;
	height: 50px;
	padding: 1px;
	padding-top: 25px;
	// margin-bottom: 80px;
	// position: absolute;
	z-index: 99999;
	width: 100%;
}

.footer {
	margin-top: 8em!important;
	background-color: $color-brand-primary;
	padding-top: 40px;
}


.other-industry {
	background: #e7e7e7;
}