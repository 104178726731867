@charset 'utf-8';



/*------------------------------------*\
  # project specific – theme
\*------------------------------------*/
/**
 * set key brand values in _brand-theme.scss
 */
@import "brand-theme";
/**
 * the original ZF _settings.scss is duplicted/customsied as _brand-settings.scss
 */
@import 'brand-settings';



/*------------------------------------*\
  # ZURB Foundation
\*------------------------------------*/
// @import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;






// COMPONENTS
//
// Build and include your project’s UI components here.
//
@import 'partials/components/header';
//
@import 'partials/components/nav';
//
@import 'partials/components/footer';
//
@import 'partials/components/containers';
//
@import 'partials/components/imgs';
//
@import 'partials/components/buttons';
//
@import 'partials/components/typography';





// HELPERS
//
// Useful tools and over rides.
//
// @import 'partials/helpers/foundation-overrides';
//
// @import 'partials/helpers/tools';

@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css';



/**
 * set wow to invisible initially (opacity: 0;) 
 * function ins app.js sets opacity to 1
 */
// .wow{ opacity: 0; } 

.ecommerce-product-slider {
	position: relative;
	margin-top: -300px!important;
	z-index: 9999;
}

.ecommerce-product-slider {
  .orbit-previous,
  .orbit-next {
    color: $medium-gray;
  }

  .orbit-bullets button {
    height: 0.65rem;
    width: 0.65rem;
  }

  .product-card {
    background-color: transparent;
    border: 1px solid $light-gray;
    padding: 1rem;
    margin-bottom: 1.5rem;  
  }

  .product-card-thumbnail {
    display: block;
    position: relative;
  }

  .product-card-title {
    font-family: 'Open sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.45rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .product-card-desc {
    color: $dark-gray;
    display: block;
    font-family: 'Open sans', sans-serif;
    font-size: 0.85rem;
  }

  .product-card-price {
    color: darken($dark-gray,30%);
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 600;
    margin-top: 0.8rem;
  }

  .product-card-sale {
    color: $medium-gray;
    display: inline-block;
    font-size: 0.85rem;
    margin-left: 0.3rem;
    text-decoration: line-through;
  }

  .product-card-colors {
    display: block;
    margin-top: 0.8rem;
  }

  .product-card-color-option {
    display: inline-block;
    height: 25px;
    width: 25px;
  }
}

.disabled {
  color: black!important;
}



