/**
 * CONTENTS
 *
 *
 * SECTION 1
 * Name.................Info here
 * Name.................Info here
 *                      line 2
 *
 * SECTION 2
 * Name.................Info here
 * Name.................Info here
 * 
 *
 **/

 .cta--button {
 	border-radius: 10px;
 }

 .button {
 	border-radius: 8px;
 	padding: 12px 16px;
 	font-weight: bold;
 	font-size: 17px;
 }